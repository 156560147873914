.container {
    padding: 30px;
}

h1 {
    font-weight: 700;
    margin-bottom: 5px;
}

p {
    margin-bottom: 30px;
}