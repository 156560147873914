@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

* {
  font-family: "Roboto";
}

p {
  color: #7e7e7e;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
  margin: 0;
}

.base-card {
  background: #e6f7ff;
  border-radius: 16px;
  padding: 30px;
}
