a, a:hover {
    text-decoration: none;
}
h3 {
    color: #fff;
}

nav {
    display: flex;
    height: 62px;
    align-items: center;
    padding: 0 40px 0 40px;
}